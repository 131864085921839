<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import moment from 'moment';
import errorHandler from '@/helpers/errorHandler'
import ExportExcel from '../../../components/exportExcel.vue';

/**
 * Starter component
 */
export default {
  page:{
    title: 'Sessions list'
  },
  head() {
    return {
      title: `Sessions`,
    };
  },
  components: {
    Layout,
    PageHeader,
    ConfirmActionDialog,
    ExportExcel
  },
  async created() {
    await this.$store.dispatch("session/getAllSessions").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      })
    await this.$store.dispatch("option/getYearOption").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      })
    await this.$store.dispatch("option/getCenterOption").then( ()=>{}).catch( err => {
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      })
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    sessions() {
      return this.$store.getters["session/allSessions"];
    },
    stages() {
      return this.$store.getters["option/years"];
    },
    centers() {
      return this.$store.getters["option/centers"];
    },
    lectures() {
      return this.$store.getters["option/chapters"];
    },
    rows() {
      return this.sessions.length;
    }
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("session/deleteSession",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
      this.$bvModal.show('deleteRecord')
      this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/sessions/' + record.id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async selectStage(){
      await this.$store.dispatch("session/getAllSessionsFilters",{stage:this.stageFilter, lecture:this.lectureFilter, center: this.centerFilter}).then( async ()=>{
        await this.$store.dispatch("option/getYearChapterOption",this.stageFilter).then(()=>{}).catch(err =>{
          console.log("No items")
        })
      }).catch( err => {
        console.log("No items")
      })
      this.lectureFilter = null;
    },
    async selectLecture(){
      await this.$store.dispatch("session/getAllSessionsFilters",{stage:this.stageFilter, lecture:this.lectureFilter, center: this.centerFilter}).then( async ()=>{
      }).catch( err => {
        console.log("No items")
      })
    }
  },
  filters:{
    formatDate(value){
    if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
    }
    },
  },
  data: () => ({    
    stageFilter: null,
    lectureFilter: null,
    centerFilter: null,
    totalRows:0,
    recordId: null,
    title: "Session List",
    items: [
      {
        text: "Attendance",
        href: "/home"
      },
      {
        text: "Sessions",
        href: "/sessions"
      }
    ],
    perPage: 20,
    currentPage: 1,
    pageOptions: [10, 20, 30],
    filter: "",
    sortBy: "date_time",
    sortDesc: true,
    filterOn: ["center","date_time","educational_year", "chapter"],
    fields: [
      {
        key: "center.name",
        label: "Center",
        sortable: true,
        selected: true
      },
      {
        key: "educational_year",
        label: "Stage",
        sortable: true,
        selected: true
      },
      {
        key: "chapter",
        label: "Lecture",
        sortable: true,
        selected: true
      },
      {
        key: "date_time",
        label: "Date",
        sortable: true,
        selected: true
      },
      {
        key: "action",
        label: "",
        sortable: false,
        selected: true,
        disabled: true
      }
    ]
  })
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Stage&nbsp;
                    <b-form-select
                      v-model="stageFilter"
                      size="sm"
                      :options="stages"
                      @change="selectStage"
                    ></b-form-select
                    >&nbsp;
                  </label>
                  <label class="d-inline-flex align-items-center ml-2">
                    Lecture&nbsp;
                    <b-form-select
                      v-model="lectureFilter"
                      size="sm"
                      :options="lectures"
                      @change="selectLecture"
                    ></b-form-select
                    >&nbsp;
                  </label>
                  <label class="d-inline-flex align-items-center ml-2">
                    Center&nbsp;
                    <b-form-select
                      v-model="centerFilter"
                      size="sm"
                      :options="centers"
                      @change="selectLecture"
                    ></b-form-select
                    >&nbsp;
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <router-link to="/sessions/create" v-if="$store.getters['user/role'] == 0">
                    <b-button variant="success" class="btn-sm mr-2">
                      <i class="mdi mdi-plus"></i>
                      Add Session
                    </b-button>
                  </router-link>
                  <export-excel :jsonData="sessions" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="sessions"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="viewProfile"
            >
            <template v-slot:cell(educational_year)="data">
                {{ data.item.educational_year.number + ' - ' + data.item.educational_year.description }}
            </template>
            <template v-slot:cell(chapter)="data">
                {{ data.item.chapter.name }}
            </template>
            <template v-slot:cell(date_time)="data">
                {{ data.item.date_time | formatDate }}
            </template>
          <template v-slot:cell(action)="data">
                <b-dropdown
                  class="btn-icon-onl"
                  right
                  toggle-class="arrow-none btn-light btn-sm"
                  v-if="$store.getters['user/role'] == 0"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal iconGray"></i>
                  </template>

                  <b-dropdown-item
                    :to="'/sessions/' + data.item.id + '/edit'"
                  >
                    <i
                      class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
  </Layout>
</template>
